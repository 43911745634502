
export default {
  data(){
    return{
      sidebarOpen:false,
      style:{
        top_menu:"py-2 text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-900  hover:text-purple-700 group flex items-center px-2  mr-2 text-md font-medium rounded-md",
        top_menu_active:'bg-purple-50 dark:bg-gray-900',

        sidebar:"text-gray-600 hover:bg-gray-50 hover:text-purple-700 group flex items-center px-2 py-2 text-sm font-medium rounded-md",
        sidebar_active:'text-purple-600 border-purple-600 border-l-4 bg-purple-50',

        svg:"group-hover:text-purple-700 dark:group-hover:text-purple-500 mr-2 flex-shrink-0 h-6 w-6",
        svg_active:"text-purple-700 dark:text-purple-500 mr-2 flex-shrink-0 h-6 w-6",
      }
    }
  },

computed: {
    isLogged() {
      return this.$store.state.general.auth.is_logged;
    },
  },
}
