
import Logo from '@/components/_parts/logo.vue';
import GoogleAnalytics from '~/components/GoogleAnalytics.vue';

export default {
  name: 'TheFooter',
  components: {
    GoogleAnalytics,
    Logo: Logo
  }
}
