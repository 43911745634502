
export default {
  data() {
    return {
      style: {
        active: 'text-purple-600  border-purple-600 border-l-4 bg-purple-50 dark:bg-gray-900 dark:text-white',
        sidebar: "text-gray-600 dark:text-gray-300 hover:bg-gray-50 hover:text-purple-700 dark:hover:bg-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md",

        svg:"group-hover:text-purple-700 dark:group-hover:text-purple-500 mr-2 flex-shrink-0 w-6 h-6 mr-2",
        svg_active:"text-purple-700 dark:text-purple-500 mr-2 flex-shrink-0 w-6 h-6 mr-2",
      
      }
    }
  },
}
