
import Header from '@/components/_parts/Header.vue';
import Footer from '@/components/_parts/Footer.vue';
// import hooliganmedia from '@/components/ads/hooliganmedia/head.vue';

export default {
  head: {
    title: "TraderForYou automated copy trading",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        hid: "description",
        name: "description",
        content: "One click send and receive signals from the top traders around the world using our Robots."
      }
    ]
  },
  data() {
    return {
      mode:"dark"
    }
  },
  components: {
    // hooliganmedia,
    'Header': Header,
    'Footer': Footer
  },

  computed: {
    isLogged() {
      return this.$store.state.general.auth.is_logged;
    },
  },

  mounted(){
    console.log(localStorage.getItem("mode"));
    if(localStorage.getItem("mode") == 'undefined' || localStorage.getItem("mode") == null){
      localStorage.setItem("mode", this.mode);
    }else{
      this.mode = localStorage.getItem('mode');
    }

    // referal system
    if(localStorage.getItem("ref") == 'undefined' || localStorage.getItem("ref") == null){
      var ref = this.$router.currentRoute.query['ref'];
      // console.log(ref);
      if(typeof ref == 'undefined'){
        // localStorage.setItem("ref", '-');
      }else{
        localStorage.setItem("ref", parseInt(ref));
      }
    }

    // login check
    if (localStorage.getItem('is_authenticated') != null && localStorage.getItem('auth_token') != null && localStorage.getItem('user_data') != null) {
      // this.$store.state.general.auth.is_logged = localStorage.getItem('is_authenticated');
      var auth_token = localStorage.getItem('auth_token');
      var user_data = JSON.parse(localStorage.getItem('user_data'));
      // console.log(auth_token,user_data)
      this.$store.dispatch('general/storeAuthData', {auth_token: auth_token, user_data: user_data});
    }
    this.userName = this.$store.state.general.auth.user_data.name;
    this.userEmail = this.$store.state.general.auth.user_data.email;

  },
  methods:{
    onChange(event) {
            // console.log(event.target.value, this.mode)
            localStorage.setItem("mode", this.mode);
        },

    signout() {
      this.$axios.setHeader('Authorization', "Bearer " + localStorage.getItem('auth_token'));
      this.$axios.get('/api/logout').then(response => {
        if(response.data.success) {

          this.$toast.show({
            type: 'success',
            title: 'Success',
            message: response.data.message,
          }); 
          
          localStorage.removeItem('auth_token');
          localStorage.removeItem('is_authenticated');
          localStorage.removeItem('user_data'); 

          this.$store.dispatch('general/resetAuthData');

          // this.onAfterSignout();

          this.$router.push('/');
        }
      }).catch(err => {
        console.log(err.response);
        // this.onAfterSignout();
          localStorage.removeItem('auth_token');
          localStorage.removeItem('is_authenticated');
          localStorage.removeItem('user_data');
          this.$store.dispatch('general/resetAuthData');
          this.$router.push('/');
      });
    }
  }
}
