
import TopMenu from '@/components/_parts/menu.vue';
import Logo from '@/components/_parts/logo.vue';
export default {
head: {
  title: "TraderForYou automated copy trading",
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    {
      hid: "description",
      name: "description",
      content: "One click send and receive signals from the top traders around the world using our Robots."
    }
  ]
},
components: {
  Logo,
  TopMenu
},
data(){
  return{
    sidebarOpen:false,
    topbarOpen:false,
    style:{
        top_menu:"py-2 text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-900  hover:text-purple-700 group flex items-center px-2  mr-2 text-md font-medium rounded-md",
        top_menu_active:'bg-purple-50 dark:bg-gray-900',

        sidebar:"text-gray-600 hover:bg-gray-50 hover:text-purple-700 group flex items-center px-2 py-2 text-sm font-medium rounded-md",
        sidebar_active:'text-purple-600 border-purple-600 border-l-4 bg-purple-50',

        svg:"group-hover:text-purple-700 dark:group-hover:text-purple-500 mr-2 flex-shrink-0 h-6 w-6",
        svg_active:"text-purple-700 dark:text-purple-500 mr-2 flex-shrink-0 h-6 w-6",
      }
  }
},
computed: {
  isLogged() {
    return this.$store.state.general.auth.is_logged;
  },
},
mounted(){
  // login check
  if (localStorage.getItem('is_authenticated') != null && localStorage.getItem('auth_token') != null && localStorage.getItem('user_data') != null) {
    // this.$store.state.general.auth.is_logged = localStorage.getItem('is_authenticated');
    var auth_token = localStorage.getItem('auth_token');
    var user_data = JSON.parse(localStorage.getItem('user_data'));
    console.log(auth_token,user_data)
    this.$store.dispatch('general/storeAuthData', {auth_token: auth_token, user_data: user_data});
  }
  this.userName = this.$store.state.general.auth.user_data.name;
  this.userEmail = this.$store.state.general.auth.user_data.email;

},

}
